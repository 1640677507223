import { ref } from "vue";

export interface UseDraggableConfig {
  type: string;
  payload?: unknown;
  draggableClass: string;
  draggableActiveClass: string;
  draggableCloneClass: string;
}

type UseDraggableReturn = ReturnType<typeof useDraggable>;

function useDraggable(element: HTMLElement, options?: Partial<UseDraggableConfig>) {
  const config: UseDraggableConfig = {
    type: "default",
    draggableClass: "mw-draggable",
    draggableActiveClass: "mw-draggable--active",
    draggableCloneClass: "mw-draggable__clone",
    ...options
  };

  /**
   * Used to disable default browser behavior of making a, img and other elements draggable,
   * which generates an incorrect drag image.
   * `-webkit-user-drag: none` exists for this purpose, but is not supported on non-webkit browsers.
   */
  function onPointerDown(event: PointerEvent) {
    const targetElement = event.target;
    if (targetElement instanceof HTMLElement && !targetElement.hasAttribute("draggable")) {
      targetElement.setAttribute("draggable", "false");
      element.addEventListener("pointerup", () => targetElement.removeAttribute("draggable"), { once: true });
    }
  }

  function onDragStart(event: DragEvent) {
    if (!event.dataTransfer) {
      return;
    }

    // We'll handle this so stop event from bubbling up
    event.stopPropagation();

    // Some kind of data is required for drag to work in Safari on iOS
    // but not text/plain data, or we'll be able to drop it on inputs
    // which is not what we want.
    event.dataTransfer.setData("custom", "dnd");

    event.dataTransfer.effectAllowed = "all";

    draggedElement.value = element;
    element.classList.add(config.draggableActiveClass, config.draggableCloneClass);
    setTimeout(() => element.classList.remove(config.draggableCloneClass));
  }

  function onDragEnd() {
    draggedElement.value = undefined;
    element.classList.remove(config.draggableActiveClass);
  }

  function destroy() {
    element.removeAttribute("draggable");
    element.classList.remove(config.draggableClass);
    element.removeEventListener("pointerdown", onPointerDown);
    element.removeEventListener("dragstart", onDragStart);
    element.removeEventListener("dragend", onDragEnd);
  }

  element.setAttribute("draggable", "true");
  element.classList.add(config.draggableClass);
  element.addEventListener("pointerdown", onPointerDown);
  element.addEventListener("dragstart", onDragStart);
  element.addEventListener("dragend", onDragEnd);

  return {
    config,
    destroy
  };
}

export interface UseDropzoneConfig {
  accepts: string[];
  dropzoneClass: string;
  dropzoneActiveClass: string;
  onDrop?: (type: string, payload?: unknown) => void;
}

type UseDropzoneReturn = ReturnType<typeof useDropzone>;

function useDropzone(element: HTMLElement, options?: Partial<UseDropzoneConfig>) {
  const config: UseDropzoneConfig = {
    accepts: ["default"],
    dropzoneClass: "mw-dropzone",
    dropzoneActiveClass: "mw-dropzone--active",
    ...options
  };

  // Used to track if we are inside the dropzone or one of its children.
  // Normally you would use event.relatedTarget for this, but it's not
  // working in Safari because of this bug: https://bugs.webkit.org/show_bug.cgi?id=66547
  let depth = 0;

  function isDropAllowed(draggableElement: HTMLElement) {
    if (draggableElement === element) {
      return false;
    }

    const draggable = draggables.get(draggableElement);
    if (!draggable) {
      return false;
    }

    return config.accepts.includes(draggable.config.type);
  }

  function onDragEnter() {
    if (!draggedElement.value || !isDropAllowed(draggedElement.value)) {
      return;
    }

    depth += 1;
    if (depth > 1) {
      return;
    }

    element.classList.add(config.dropzoneActiveClass);
  }

  function onDragLeave() {
    if (!draggedElement.value || !isDropAllowed(draggedElement.value)) {
      return;
    }

    depth -= 1;
    if (depth > 0) {
      return;
    }

    element.classList.remove(config.dropzoneActiveClass);
  }

  function onDragOver(event: DragEvent) {
    if (!draggedElement.value || !isDropAllowed(draggedElement.value)) {
      return;
    }

    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = "move";
    }

    event.preventDefault();
  }

  function onDrop(event: DragEvent) {
    if (!draggedElement.value || !isDropAllowed(draggedElement.value)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    depth = 0;

    element.classList.remove(config.dropzoneActiveClass);

    const draggable = draggables.get(draggedElement.value);
    if (draggable && config.onDrop) {
      config.onDrop(draggable.config.type, draggable.config.payload);
    }
  }

  function destroy() {
    element.classList.remove(config.dropzoneClass);
    element.removeEventListener("dragover", onDragOver);
    element.removeEventListener("dragenter", onDragEnter);
    element.removeEventListener("dragleave", onDragLeave);
    element.removeEventListener("drop", onDrop);
  }

  element.classList.add(config.dropzoneClass);
  element.addEventListener("dragenter", onDragEnter);
  element.addEventListener("dragleave", onDragLeave);
  element.addEventListener("dragover", onDragOver);
  element.addEventListener("drop", onDrop);

  return {
    config,
    destroy
  };
}

/**
 * Draggables
 */

const draggedElement = ref<HTMLElement>();
const draggables = new WeakMap<HTMLElement, UseDraggableReturn>();

export function registerDraggable(element: HTMLElement, options?: Partial<UseDraggableConfig>) {
  if (draggables.get(element)) {
    unregisterDraggable(element);
  }

  const draggable = useDraggable(element, options);
  draggables.set(element, draggable);
}

export function unregisterDraggable(element: HTMLElement) {
  const draggable = draggables.get(element);
  if (draggable) {
    draggable.destroy();
  }
}

/**
 * Dropzones
 */
const dropzones = new WeakMap<HTMLElement, UseDropzoneReturn>();

export function registerDropzone(element: HTMLElement, options?: Partial<UseDropzoneConfig>) {
  if (dropzones.get(element)) {
    unregisterDropzone(element);
  }

  const dropzone = useDropzone(element, options);
  dropzones.set(element, dropzone);
}

export function unregisterDropzone(element: HTMLElement) {
  const dropzone = dropzones.get(element);
  if (dropzone) {
    dropzone.destroy();
  }
}
