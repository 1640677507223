/**
 * Base class for API response errors
 */
export abstract class AbstractResponseError extends Error {
  constructor(public response: Response, message?: string) {
    super(message);
    // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#support-for-newtarget
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class UnexpectedResponseError extends AbstractResponseError {
  constructor(public response: Response, message = "Unexpected response") {
    super(response, message);
  }
}

export class ValidationFailedResponseError extends AbstractResponseError {
  constructor(public response: Response, message = "Validation failed") {
    super(response, message);
  }
}
