<script lang="ts">
import { type Component, defineComponent } from "vue";

const files = import.meta.glob<Component>(["@/views/*.vue"], {
  eager: true,
  import: "default"
});

const components = Object.entries(files).reduce((result, [path, component]) => {
  const name = path.substring(path.lastIndexOf("/") + 1, path.lastIndexOf("."));
  return { ...result, [name]: component };
}, {});

export default defineComponent({ components });
</script>
