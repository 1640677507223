import "@/polyfills";
import "@/base";

import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import { createApp } from "vue";

import App from "@/App.vue";
import { config } from "@/config";
import { createResetState } from "@/stores/plugins";

const pinia = createPinia();
pinia.use(createResetState());

const app = createApp(App).use(pinia);

if (config.sentry.enabled) {
  Sentry.init({
    app,
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    tracesSampleRate: 1.0,
    integrations: [
      new ExtraErrorDataIntegration({
        // Limit of how deep the object serializer should go. Anything deeper than limit will
        // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
        // a primitive value. Defaults to 3.
        depth: 3
      })
    ]
  });
}

// Force preservation of whitespace in templates or adjacent buttons
// will touch each other. Buttons should probably be wrapped in a
// container element and styled instead.
app.config.compilerOptions.whitespace = "preserve";

document.querySelectorAll(".vue-app").forEach((el) => {
  app.mount(el);
});
