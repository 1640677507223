<script setup lang="ts">
import { computed, ref } from "vue";

import ExerciseVideoModal from "@/components/ExerciseVideoModal.vue";
import MwButton from "@/components/MwButton.vue";
import MwIcon from "@/components/MwIcon.vue";
import MwIconButton from "@/components/MwIconButton.vue";
import MwImageButton from "@/components/MwImageButton.vue";
import type { Exercise } from "@/types";

interface Props {
  exercise: Exercise;
  isAdded?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isAdded: false
});

const emit = defineEmits<{
  (event: "assignLevel", exercise: Exercise): void;
  (event: "assignLevelNewExercise", exercise: Exercise): void;
  (event: "showLevels", exercise: Exercise): void;
  (event: "edit", exercise: Exercise): void;
  (event: "favorite", exercise: Exercise): void;
  (event: "unfavorite", exercise: Exercise): void;
}>();

const showVideoModal = ref(false);

const toggleFavoriteIcon = computed(() => (props.exercise.favorite ? "star-fill" : "star"));
const onToggleFavorite = () => {
  if (props.exercise.favorite) {
    emit("unfavorite", props.exercise);
  } else {
    emit("favorite", props.exercise);
  }
};
</script>

<template>
  <div
    class="exercise-card"
    :class="{
      'exercise-card--is-personal': props.exercise.personal,
      'exercise-card--is-favorite': props.exercise.favorite,
      'exercise-card--is-added': isAdded
    }"
  >
    <MwImageButton class="exercise-card__image" @click="showVideoModal = true">
      <img :alt="exercise.title" :src="props.exercise.keyframeUrl" loading="lazy" />
      <template #overlay>
        <MwIcon name="play-circle-fill" />
      </template>
    </MwImageButton>
    <div class="exercise-card__header">
      <h4 class="exercise-card__title">{{ props.exercise.title }}</h4>
      <div class="exercise-card__header-actions">
        <MwIconButton
          v-if="props.exercise.personal"
          class="exercise-card__edit-btn"
          icon="pencil-fill"
          @click="emit('edit', props.exercise)"
        />
        <MwIconButton
          :disabled="!exercise.progressingIds.length && !exercise.regressingIds.length"
          variant="secondary"
          class="exercise-card__levels-btn"
          icon="bar-chart-line-fill"
          @click="emit('showLevels', props.exercise)"
        />
        <MwIconButton
          :icon="toggleFavoriteIcon"
          size="lg"
          class="exercise-card__favorite-btn"
          @click="onToggleFavorite()"
        />
      </div>
    </div>
    <div class="exercise-card__description">
      <span v-if="props.exercise.description" class="exercise-card__excerpt">
        {{ props.exercise.description }}
      </span>
      <span v-else class="text-muted">(Ingen beskrivelse)</span>
    </div>
    <div class="exercise-card__actions">
      <MwButton size="sm" variant="secondary" outline @click="emit('assignLevelNewExercise', props.exercise)">
        Indsæt ny række
      </MwButton>
      <MwButton size="sm" @click="emit('assignLevel', props.exercise)"> Indsæt </MwButton>
    </div>
    <ExerciseVideoModal v-if="showVideoModal" v-model="showVideoModal" :exercise="props.exercise" />
  </div>
</template>

<style lang="scss" scoped>
.exercise-card {
  display: grid;
  grid-template-areas:
    "image image"
    "header header"
    "description description"
    "actions actions";
  grid-template-rows: auto auto 1fr auto;
  align-items: start;
  overflow: hidden;
  transition: background-color 150ms ease-out;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  gap: 0.5rem;

  &--is-personal {
    border-color: rgba(#0192cf, 65%);
    background-color: rgba(#0192cf, 10%);
  }

  &--is-favorite {
    border-color: rgba(#e4b301, 65%);
    background-color: rgba(#e4b301, 10%);
  }

  &--is-added {
    border-color: rgba(#198754, 65%);
    background-color: rgba(#198754, 10%);
  }

  &__image {
    grid-area: image;
  }

  &__header {
    display: grid;
    grid-area: header;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding-inline: 0.75rem;
    gap: 0.5rem;
  }

  &__title {
    margin: 0;
    font-size: 1rem;
    word-break: break-word;
  }

  &__header-actions {
    display: grid;
    grid-auto-flow: column;
    gap: 0.25rem;
    align-items: center;
    align-self: start;
  }

  &__description {
    grid-area: description;
    padding-inline: 0.75rem;
    font-size: 0.85rem;
  }

  &__excerpt {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__actions {
    display: grid;
    grid-area: actions;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    align-items: center;
    padding: 0.75rem;
    gap: 0.5rem;
  }

  &__favorite-btn {
    color: #fed336;
  }

  &__edit-btn {
    color: #495057;
  }

  &__levels-btn {
    color: #6c757d;
  }
}
</style>
