/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Returns wrapped function that won't execute until `waitFor` ms after last call.
 */
export function useDebounce<F extends (...args: any[]) => any>(fn: F, waitFor: number) {
  let timeout: number;

  return (...args: Parameters<F>): Promise<ReturnType<F>> => {
    return new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        return resolve(fn(...args));
      }, waitFor);
    });
  };
}
