import { arrayMoveMutable } from "array-move";
import { cloneDeep, isEqual, uniq } from "lodash-es";
import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";

import { ExerciseProgramsService } from "@/services";
import { useBarometerTemplatesStore, useExercisesStore } from "@/stores";
import type {
  AssignedExercise,
  AssignedExerciseComputed,
  AssignedExerciseFormData,
  AssignedExerciseLevel,
  AssignedExerciseLevelComputed,
  AssignedExerciseLevelFormData,
  Exercise,
  ExerciseProgram,
  ExerciseProgramFormData
} from "@/types";

export type Position = {
  exerciseIndex: number;
  levelIndex: number;
};

interface State {
  loadState: "idle" | "pending" | "finished" | "failed";
  saveState: "idle" | "pending" | "finished" | "failed";
  exerciseProgram: Readonly<ExerciseProgram> | undefined;
  draft: ExerciseProgram;
  selectedPosition: Position | undefined;
}

export const useExerciseProgramEditorStore = defineStore("exerciseProgramEditor", () => {
  const state = reactive<State>({
    loadState: "idle",
    saveState: "idle",
    exerciseProgram: undefined,
    draft: {
      id: -1,
      patientId: null,
      title: "",
      comment: "",
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      progrediateLocked: false,
      barometerTemplateId: null,
      assignedExercises: []
    },
    selectedPosition: undefined
  });

  const exercisesStore = useExercisesStore();
  const barometerTemplatesStore = useBarometerTemplatesStore();

  const isLoading = computed(() => state.loadState === "pending");
  const isSaving = computed(() => state.saveState === "pending");

  /**
   * Check if draft is different from original exercise program
   */
  const isDirty = computed(() => {
    const draft = cloneDeep(state.draft);

    draft.assignedExercises = draft.assignedExercises.reduce<AssignedExercise[]>((result, assignedExercise) => {
      result.push({
        ...assignedExercise,
        exercises: assignedExercise.exercises.filter((level) => !!level)
      });

      return result;
    }, []);

    return !isEqual(draft, state.exerciseProgram);
  });

  /**
   * Computed levels including original and customized exercises
   */
  const assignedExercises = computed(() => {
    return state.draft.assignedExercises.reduce<AssignedExerciseComputed[]>((result, assignedExercise) => {
      const levels = assignedExercise.exercises.map<AssignedExerciseLevelComputed | null>((level) => {
        if (!level) {
          return null;
        }

        const originalExercise = exercisesStore.getExercise(level.id);
        if (!originalExercise) {
          return null;
        }

        const customizedExercise: Record<string, unknown> = cloneDeep(originalExercise);

        // Copy customized attributes to the exercise
        Object.keys(customizedExercise).forEach((_key) => {
          const key = _key as keyof typeof level;
          if (level[key] !== undefined && level[key] !== null && level[key] !== "") {
            customizedExercise[key] = level[key];
          }
        });

        return {
          ...level,
          originalExercise,
          customizedExercise: customizedExercise as Exercise
        };
      });

      result.push({ ...assignedExercise, exercises: levels });

      return result;
    }, []);
  });

  const barometerTemplate = computed(() => {
    if (!state.draft.barometerTemplateId) {
      return undefined;
    }

    return barometerTemplatesStore.getBarometerTemplate(state.draft.barometerTemplateId);
  });

  const exerciseIds = computed(() => {
    const ids = assignedExercises.value.reduce<number[]>((result, assignedExercise) => {
      assignedExercise.exercises.forEach((exercise) => {
        if (exercise) {
          result.push(exercise.id);
        }
      });
      return result;
    }, []);

    return uniq(ids);
  });

  /**
   * Create draft based on `exerciseProgram`.
   * Note: Drafts needs to contain three levels per assigned exercise to render correctly.
   */
  function createDraft(exerciseProgram: ExerciseProgram) {
    const draft = cloneDeep(exerciseProgram);

    draft.assignedExercises = draft.assignedExercises.reduce<AssignedExercise[]>((result, assignedExercise) => {
      const exercises: (AssignedExerciseLevel | null)[] = [
        ...assignedExercise.exercises,
        ...new Array<AssignedExerciseLevel | null>(3).fill(null)
      ].slice(0, 3);

      result.push({ ...assignedExercise, exercises });

      return result;
    }, []);

    return draft;
  }

  async function load(id: number) {
    try {
      state.loadState = "pending";
      state.exerciseProgram = await ExerciseProgramsService.getExerciseProgram(id);
      state.draft = createDraft(state.exerciseProgram);
      state.loadState = "finished";
    } catch (error) {
      state.loadState = "failed";
      throw error;
    }
  }

  async function save(exerciseProgram: ExerciseProgram = state.draft) {
    try {
      state.saveState = "pending";
      state.exerciseProgram = await ExerciseProgramsService.updateExerciseProgram(exerciseProgram);
      state.draft = createDraft(state.exerciseProgram);
      state.saveState = "finished";
    } catch (error) {
      state.saveState = "failed";
      throw error;
    }
  }

  function hasExercise(exerciseId: number) {
    return exerciseIds.value.includes(exerciseId);
  }

  function updateProgram(data: ExerciseProgramFormData) {
    Object.assign(state.draft, data);
  }

  function isValidPosition(position = state.selectedPosition): position is Position {
    return (
      position !== undefined &&
      state.draft.assignedExercises[position.exerciseIndex]?.exercises[position.levelIndex] !== undefined
    );
  }

  function select(position: Position) {
    if (!isValidPosition(position)) {
      return;
    }

    return (state.selectedPosition = position);
  }

  function selectNextLevel() {
    if (
      !isValidPosition(state.selectedPosition) ||
      state.selectedPosition.levelIndex >=
        state.draft.assignedExercises[state.selectedPosition.exerciseIndex].exercises.length - 1
    ) {
      return false;
    }

    return select({
      exerciseIndex: state.selectedPosition.exerciseIndex,
      levelIndex: state.selectedPosition.levelIndex + 1
    });
  }

  function selectNextExercise() {
    if (
      !isValidPosition(state.selectedPosition) ||
      state.selectedPosition.exerciseIndex >= state.draft.assignedExercises.length - 1
    ) {
      return false;
    }

    return select({
      exerciseIndex: state.selectedPosition.exerciseIndex + 1,
      levelIndex: 0
    });
  }

  function addExercise() {
    state.draft.assignedExercises.push({
      sets: 1,
      repetitions: 10,
      registerVas: false,
      paused: false,
      exercises: [null, null, null]
    });

    return select({
      exerciseIndex: state.draft.assignedExercises.length - 1,
      levelIndex: 0
    });
  }

  function updateExercise(data: AssignedExerciseFormData, exerciseIndex: number) {
    Object.assign(state.draft.assignedExercises[exerciseIndex], data);
  }

  function removeExercise(exerciseIndex: number) {
    state.draft.assignedExercises.splice(exerciseIndex, 1);

    if (!state.draft.assignedExercises.length) {
      state.selectedPosition = undefined;
    }

    if (!state.selectedPosition) {
      return;
    }

    if (
      state.selectedPosition.exerciseIndex > exerciseIndex ||
      state.selectedPosition.exerciseIndex > state.draft.assignedExercises.length - 1
    ) {
      state.selectedPosition.exerciseIndex--;
    }
  }

  function moveExercise(fromIndex: number, toIndex: number) {
    arrayMoveMutable(state.draft.assignedExercises, fromIndex, toIndex);
  }

  function assignLevel(exercise: Exercise, position = state.selectedPosition) {
    if (!isValidPosition(position)) {
      return;
    }

    state.draft.assignedExercises[position.exerciseIndex].exercises[position.levelIndex] = {
      id: exercise.id,
      title: "",
      description: "",
      comment: ""
    };
  }

  function clearLevel(position = state.selectedPosition) {
    if (!isValidPosition(position)) {
      return;
    }

    state.draft.assignedExercises[position.exerciseIndex].exercises[position.levelIndex] = null;
  }

  function updateLevel(data: AssignedExerciseLevelFormData, position = state.selectedPosition) {
    if (!isValidPosition(position)) {
      return;
    }

    // We checked that the position is valid, so we can use the non-null assertion operator
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Object.assign(state.draft.assignedExercises[position.exerciseIndex].exercises[position.levelIndex]!, data);
  }

  function moveLevel(from: Position, to: Position) {
    if (!isValidPosition(from) || !isValidPosition(to)) {
      return false;
    }

    const fromExercise = state.draft.assignedExercises[from.exerciseIndex].exercises[from.levelIndex];
    const toExercise = state.draft.assignedExercises[to.exerciseIndex].exercises[to.levelIndex];

    state.draft.assignedExercises[from.exerciseIndex].exercises[from.levelIndex] = toExercise;
    state.draft.assignedExercises[to.exerciseIndex].exercises[to.levelIndex] = fromExercise;
  }

  return {
    ...toRefs(state),
    isLoading,
    isSaving,
    isDirty,
    assignedExercises,
    barometerTemplate,
    exerciseIds,
    hasExercise,
    load,
    save,
    updateProgram,
    isValidPosition,
    select,
    selectNextLevel,
    selectNextExercise,
    addExercise,
    updateExercise,
    removeExercise,
    moveExercise,
    assignLevel,
    clearLevel,
    updateLevel,
    moveLevel
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExerciseProgramEditorStore, import.meta.hot));
}
