<script setup lang="ts">
import { computed } from "vue";

import ExerciseFinderFilter from "@/components/ExerciseFinderFilter.vue";
import MwDelayedTemplate from "@/components/MwDelayedTemplate.vue";
import type { Exercise, ExerciseFilter, ExerciseScope, TagCategory } from "@/types";

interface Props {
  exercises: Exercise[];
  limit?: number;
  isLoading?: boolean;
  tagCategories?: TagCategory[];
  disabledTags?: string[];
  filter?: ExerciseFilter;
  scope?: ExerciseScope;
}

const props = withDefaults(defineProps<Props>(), {
  limit: undefined,
  isLoading: false,
  tagCategories: () => [],
  disabledTags: () => [],
  filter: undefined,
  scope: undefined
});

const emit = defineEmits<{
  (event: "filterChange", filter: ExerciseFilter | undefined): void;
}>();

const results = computed(() => ({
  exercises: props.exercises.slice(0, props.limit),
  count: props.exercises.length,
  limit: props.limit
}));
</script>

<template>
  <div class="exercise-finder">
    <div class="exercise-finder__header">
      <h4 class="exercise-finder__title">Øvelser</h4>
      <div class="exercise-finder__actions">
        <slot name="actions" />
      </div>
      <ExerciseFinderFilter
        :filter="props.filter"
        :scope="props.scope"
        :tag-categories="props.tagCategories"
        :disabled-tags="props.disabledTags"
        class="exercise-finder__filter"
        @change="(event) => emit('filterChange', event)"
      />
    </div>
    <div class="exercise-finder__content">
      <MwDelayedTemplate v-if="props.isLoading"> Indlæser... </MwDelayedTemplate>
      <template v-else>
        <p v-if="!results.count">Ingen øvelser matcher de valgte søgekriterier.</p>
        <div v-else class="exercise-finder__exercises-grid">
          <slot v-for="exercise in results.exercises" v-bind="{ exercise }" />
        </div>
        <p v-if="results.limit && results.count > results.limit" class="exercise-finder__limited-results-message">
          Viser {{ results.limit }} ud af {{ results.count }} øvelser. Du kan præcisere din søgning ved at bruge
          søgekriterierne i toppen.
        </p>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.exercise-finder {
  &__header {
    display: grid;
    position: sticky;
    z-index: 10;
    top: 0;
    grid-template-areas:
      "title actions"
      "filter filter";
    grid-template-columns: 1fr auto;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    gap: 1.5rem;
  }

  &__title {
    grid-area: title;
    margin: 0;
  }

  &__actions {
    grid-area: actions;
  }

  &__filter {
    grid-area: filter;
  }

  &__content {
    padding: 1.5rem;
  }

  &__exercises-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }

  &__limited-results-message {
    margin-top: 1rem;
    color: #6c757d;
  }
}
</style>
