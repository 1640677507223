<script setup lang="ts">
import { flip, offset, shift, size, useFloating } from "@floating-ui/vue";
import TomSelect from "tom-select";
import type { TomOption } from "tom-select/dist/types/types";
import { computed, onMounted, ref, watch } from "vue";

import type { TagCategory } from "@/types";

interface Props {
  options: TagCategory[];
  modelValue?: string[];
  disabledTags?: string[];
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  disabledTags: () => []
});

const emit = defineEmits<{
  (event: "update:modelValue", value: string[]): void;
  (event: "change", value: string[]): void;
}>();

const input = ref<HTMLSelectElement>();

const isSelected = (value: string) => {
  return props.modelValue.includes(value);
};

const isDisabled = (value: string) => {
  return props.disabledTags.includes(value);
};

const options = computed(() => {
  return props.options.reduce<TomOption[]>((opts, tagCategory) => {
    tagCategory.tags.forEach((tag) => {
      opts.push({
        optgroup: tagCategory.title,
        value: tag,
        text: tag,
        selected: isSelected(tag),
        disabled: isDisabled(tag) && !isSelected(tag)
      });
    });
    return opts;
  }, []);
});

const optionGroups = computed(() => {
  return props.options.reduce<TomOption[]>((optgroups, tagCategory) => {
    optgroups.push({
      value: tagCategory.title,
      label: tagCategory.title
    });
    return optgroups;
  }, []);
});

onMounted(() => {
  if (!input.value) {
    return;
  }

  const tomSelect = new TomSelect(input.value, {
    dropdownParent: "body",
    wrapperClass: "ts-wrapper mw-tag-selector",
    dropdownClass: "ts-dropdown mw-tag-selector__dropdown",
    dropdownContentClass: "ts-dropdown-content mw-tag-selector__dropdown-content",
    plugins: ["optgroup_columns", "checkbox_options", "no_active_items", "remove_button"],
    render: { no_results: false },
    maxOptions: undefined
  });

  const floating = useFloating(ref(tomSelect.control), ref(tomSelect.dropdown), {
    placement: "bottom-start",
    middleware: [
      offset({ mainAxis: 8 }),
      flip(),
      shift(),
      size({
        padding: 8,
        apply({ availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`
          });
        }
      })
    ]
  });

  watch([floating.strategy, floating.x, floating.y], ([strategy, x, y]) => {
    Object.assign(tomSelect.dropdown.style, {
      position: strategy,
      top: `${y}px`,
      left: `${x}px`
    });
  });

  tomSelect.hook("instead", "positionDropdown", () => {
    floating.update();
  });

  tomSelect.on("item_add", () => {
    tomSelect.setTextboxValue("");
  });

  tomSelect.on("change", (value: string[]) => {
    emit("update:modelValue", [...value]);
    emit("change", [...value]);
  });

  tomSelect.on("dropdown_close", () => {
    tomSelect.clearActiveOption();
  });

  watch([options, optionGroups], ([opts, optgroups]) => {
    tomSelect.setupOptions(opts, optgroups);
    opts.forEach((option) => tomSelect.updateOption(option.value, option));
    tomSelect.refreshOptions(false);
  });

  watch(
    () => props.modelValue,
    (modelValue) => {
      // Delaying call to setValue is required because TomSelect
      // options need to be done rendering, or it won't have any effect.
      setTimeout(() => tomSelect.setValue(modelValue, true), 150);
    },
    { immediate: true }
  );
});
</script>

<template>
  <select ref="input" multiple></select>
</template>

<style lang="scss">
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "tom-select/dist/css/tom-select.bootstrap5";

.mw-tag-selector {
  &__dropdown {
    width: auto;
    max-width: 100%;
    margin: 0;
    overflow-x: hidden;

    .optgroup-header,
    .option {
      overflow: hidden;
      font-size: 1rem;
      white-space: nowrap;
    }

    .optgroup-header {
      margin-bottom: 5px;
      padding: 0 15px 5px;
      border-bottom: 1px solid #d0d0d0;
      font-weight: 600;
    }

    .option {
      text-overflow: ellipsis;
    }
  }

  &__dropdown-content {
    display: flex;
    max-height: unset;
    row-gap: 15px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }
}
</style>
