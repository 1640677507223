<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

import MwIconButton from "@/components/MwIconButton.vue";
import type { Exercise } from "@/types";

interface Props {
  selectedExercise: Exercise;
  progressingExercises?: Exercise[];
  regressingExercises?: Exercise[];
}

const props = withDefaults(defineProps<Props>(), {
  progressingExercises: () => [],
  regressingExercises: () => []
});

const emit = defineEmits<{
  (event: "close"): void;
}>();

const container = ref<HTMLElement>();

onMounted(() => {
  container.value?.focus();
});

// TODO: This smells. Find a better way to scroll to the top of container.
watch(
  () => props.selectedExercise,
  () => container.value?.parentElement?.scroll({ top: 0, behavior: "smooth" })
);
</script>

<template>
  <div ref="container" class="exercise-levels" tabindex="-1" @keydown.esc.stop="emit('close')">
    <div class="exercise-levels__header">
      <h3 class="h4 exercise-levels__title">Øvelsesniveauer</h3>
      <MwIconButton class="exercise-levels__close-btn" icon="x-lg" size="lg" aria-label="Luk" @click="emit('close')" />
    </div>
    <div class="exercise-levels__content">
      <section>
        <h5 class="text-muted">Valgt øvelse</h5>
        <div class="exercise-levels__exercises-grid">
          <slot v-bind="{ exercise: props.selectedExercise }" />
        </div>
      </section>

      <section>
        <h5 class="text-muted">Progredierende øvelser</h5>
        <div v-if="props.progressingExercises.length" class="exercise-levels__exercises-grid">
          <slot v-for="exercise in props.progressingExercises" v-bind="{ exercise }" />
        </div>
        <p v-else>Den valgte øvelse har ingen progredierende øvelser</p>
      </section>

      <section>
        <h5 class="text-muted">Regredierende øvelser</h5>
        <div v-if="props.regressingExercises.length" class="exercise-levels__exercises-grid">
          <slot v-for="exercise in props.regressingExercises" v-bind="{ exercise }" />
        </div>
        <p v-else>Den valgte øvelse har ingen regredierende øvelser</p>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.exercise-levels {
  background-color: white;

  &__header {
    display: flex;
    position: sticky;
    z-index: 10;
    top: 0;
    flex-shrink: 0;
    align-items: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }

  &__title {
    margin: 0;
  }

  &__close-btn {
    margin-left: auto;
    color: #6c757d;
  }

  &__content {
    display: grid;
    gap: 1.5rem;
    padding: 1rem 1.5rem;
  }

  &__exercises-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
</style>
