<script setup lang="ts">
import { watch } from "vue";

import AssignedExerciseForm from "@/components/AssignedExerciseForm.vue";
import AssignedExerciseLevel from "@/components/AssignedExerciseLevel.vue";
import MwButton from "@/components/MwButton.vue";
import MwIconButton from "@/components/MwIconButton.vue";
import { useTemplateRefsList } from "@/composables";
import { vDraggable, vDropzone } from "@/directives";
import type { Position } from "@/stores";
import type {
  AssignedExerciseComputed,
  AssignedExerciseFormData,
  AssignedExerciseLevelFormData,
  BarometerTemplate,
  Exercise
} from "@/types";

type Props = {
  assignedExercises: AssignedExerciseComputed[];
  selectedPosition?: Position;
  barometerTemplate?: BarometerTemplate | undefined;
};

const props = withDefaults(defineProps<Props>(), {
  selectedPosition: undefined,
  barometerTemplate: undefined
});

const emit = defineEmits<{
  (event: "addExercise"): void;
  (event: "removeExercise", exerciseIndex: number): void;
  (event: "updateExercise", payload: { data: AssignedExerciseFormData; exerciseIndex: number }): void;
  (event: "moveExercise", payload: { fromIndex: number; toIndex: number }): void;
  (event: "selectLevel", position: Position): void;
  (event: "assignLevel", payload: { exercise: Exercise; position: Position }): void;
  (event: "clearLevel", position: Position): void;
  (event: "updateLevel", payload: { data: AssignedExerciseLevelFormData; position: Position }): void;
  (event: "moveLevel", payload: { fromPosition: Position; toPosition: Position }): void;
}>();

const assignedExercisesRefsList = useTemplateRefsList<HTMLElement>();

watch(
  () => props.selectedPosition,
  (position) => {
    if (position) {
      assignedExercisesRefsList.value[position.exerciseIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      });
    }
  },
  { flush: "post" }
);

const isSelected = (exerciseIndex: number, levelIndex: number) => {
  return (
    props.selectedPosition &&
    exerciseIndex === props.selectedPosition.exerciseIndex &&
    levelIndex === props.selectedPosition.levelIndex
  );
};
</script>

<template>
  <div class="assigned-exercises">
    <div
      v-for="(assignedExercise, exerciseIndex) in props.assignedExercises"
      :ref="(el) => assignedExercisesRefsList.set(el)"
      :key="exerciseIndex"
      v-draggable:assigned-exercise="{ payload: exerciseIndex }"
      v-dropzone="{
        accepts: ['assigned-exercise'],
        onDrop: (type: string, payload: unknown) => {
          emit('moveExercise', { fromIndex: payload as number, toIndex: exerciseIndex })
        }
      }"
      class="assigned-exercises__exercises"
    >
      <div class="assigned-exercises__header">
        <span class="assigned-exercises__number">{{ exerciseIndex + 1 }}</span>
        <AssignedExerciseForm
          class="assigned-exercises__form"
          :assigned-exercise="assignedExercise"
          :barometer-template="props.barometerTemplate"
          @change="(data) => emit('updateExercise', { data, exerciseIndex })"
        />
        <div class="assigned-exercises__actions">
          <MwIconButton icon="grip-vertical" size="lg" class="assigned-exercises__move-btn" aria-label="Flyt" />
          <MwIconButton
            icon="x-lg"
            size="lg"
            class="assigned-exercises__remove-btn"
            aria-label="Fjern"
            @click="emit('removeExercise', exerciseIndex)"
          />
        </div>
      </div>
      <div class="assigned-exercises__levels">
        <AssignedExerciseLevel
          v-for="(level, levelIndex) in assignedExercise.exercises"
          :key="levelIndex"
          v-draggable:assigned-level="{ payload: { exerciseIndex, levelIndex } }"
          v-dropzone="{
            accepts: ['assigned-level', 'exercise'],
            onDrop: (type: string, payload: unknown) => {
              if (type === 'assigned-level') {
                emit('moveLevel', {
                  fromPosition: payload as Position,
                  toPosition: { exerciseIndex, levelIndex }
                })
              }
              if (type === 'exercise') {
                emit('assignLevel', {
                  exercise: payload as Exercise,
                  position: { exerciseIndex, levelIndex }
                });
              }
            }
          }"
          :level="level"
          :is-selected="isSelected(exerciseIndex, levelIndex)"
          @select="emit('selectLevel', { exerciseIndex, levelIndex })"
          @clear="emit('clearLevel', { exerciseIndex, levelIndex })"
          @update="(data) => emit('updateLevel', { position: { exerciseIndex, levelIndex }, data })"
        >
          <template #placeholder>
            <span class="assigned-exercises__placeholder">Niveau {{ levelIndex + 1 }}</span>
          </template>
        </AssignedExerciseLevel>
      </div>
    </div>
    <MwButton class="assigned-exercises__add-exercise-btn" outline @click="emit('addExercise')">Tilføj række</MwButton>
  </div>
</template>

<style lang="scss" scoped>
.assigned-exercises {
  display: grid;
  grid-auto-rows: min-content;

  &__exercises {
    border-bottom: 1px solid #ddd;
    background-color: #f8f9fa;
  }

  &__exercises:nth-child(even) {
    background-color: rgba(#0192cf, 10%);
  }

  &__header {
    display: grid;
    grid-template-areas: "number form actions";
    grid-template-columns: 2rem 1fr auto;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid rgba(#000, 3%);
    background-color: rgba(#000, 3%);
    gap: 1rem;
  }

  &__number {
    grid-area: number;
    color: rgba(black, 50%);
    font-size: 2rem;
    font-weight: bold;
  }

  &__form {
    grid-area: form;
  }

  &__actions {
    display: grid;
    grid-area: actions;
    grid-auto-flow: column;
    gap: 0.5rem;
  }

  &__remove-btn {
    color: #6c757d;
  }

  &__move-btn {
    color: #6c757d;
    cursor: move;
  }

  &__levels {
    display: grid;
    grid-auto-rows: minmax(200px, auto);
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 1.5rem;
  }

  &__placeholder {
    color: rgba(black, 15%);
    font-size: 2rem;
  }

  &__add-exercise-btn {
    justify-self: end;
    margin: 1rem 1.5rem;
  }
}
</style>
