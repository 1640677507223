<script setup lang="ts">
import { isEqual } from "lodash-es";
import { computed, reactive, watch } from "vue";

import MwButton from "@/components/MwButton.vue";
import MwTagSelector from "@/components/MwTagSelector.vue";
import { useDebounce, useScopedId } from "@/composables";
import { defaultFilter } from "@/stores";
import type { ExerciseFilter, ExerciseScope, TagCategory } from "@/types";

interface Props {
  tagCategories: TagCategory[];
  filter?: ExerciseFilter;
  scope?: ExerciseScope;
  disabledTags?: string[];
}

const props = withDefaults(defineProps<Props>(), {
  filter: undefined,
  scope: undefined,
  disabledTags: () => []
});

const emit = defineEmits<{
  (event: "change", value: ExerciseFilter | undefined): void;
}>();

const filterDraft = reactive<ExerciseFilter>({ ...defaultFilter, ...props.filter });
const isDefaultFilter = computed(() => isEqual(filterDraft, defaultFilter));

const onInput = useDebounce((value: string) => {
  filterDraft.query = value;
  emit("change", filterDraft);
}, 300);

watch(
  () => props.filter,
  (filter) => Object.assign(filterDraft, filter ?? defaultFilter),
  { deep: true }
);

const { id } = useScopedId<keyof ExerciseFilter>();
</script>

<template>
  <div class="exercise-finder-filter">
    <input
      class="form-control exercise-finder-filter__query"
      type="text"
      placeholder="Søg efter øvelse..."
      :value="filterDraft.query"
      @input="(event) => onInput((event.target as HTMLInputElement).value)"
      @keydown.enter.prevent="(event) => filterDraft.query = (event.target as HTMLInputElement).value"
    />
    <div class="exercise-finder-filter__tags">
      <MwTagSelector
        v-model="filterDraft.tags"
        placeholder="Filtrér på tags..."
        :options="props.tagCategories"
        :disabled-tags="props.disabledTags"
        @change="emit('change', filterDraft)"
      />
    </div>
    <div class="form-group exercise-finder-filter__toggles">
      <div class="form-check form-check-inline form-switch">
        <label :for="id('onlyFavorites')" class="form-check-label">Vis kun favoritter</label>
        <input
          :id="id('onlyFavorites')"
          v-model="filterDraft.onlyFavorites"
          :value="true"
          class="form-check-input"
          type="checkbox"
          role="switch"
          @change="emit('change', filterDraft)"
        />
      </div>
      <div v-if="props.scope?.patientId" class="form-check form-check-inline form-switch">
        <label :for="id('onlyPersonal')" class="form-check-label">Vis kun personlige</label>
        <input
          :id="id('onlyPersonal')"
          v-model="filterDraft.onlyPersonal"
          :value="true"
          class="form-check-input"
          type="checkbox"
          role="switch"
          @change="emit('change', filterDraft)"
        />
      </div>
    </div>
    <div class="exercise-finder-filter__reset">
      <MwButton
        v-if="!isDefaultFilter"
        class="p-0 text-danger"
        variant="link"
        size="sm"
        @click="emit('change', undefined)"
      >
        Nulstil filtre
      </MwButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.exercise-finder-filter {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas:
    "query query tags tags tags"
    "toggles toggles toggles reset reset";
  align-items: center;
  background-color: #fff;
  gap: 0.75rem 1rem;

  &__query {
    grid-area: query;
  }

  &__tags {
    grid-area: tags;
  }

  &__toggles {
    grid-area: toggles;
  }

  &__reset {
    grid-area: reset;
    justify-self: end;
  }
}
</style>
